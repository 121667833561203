$font_varela_round: 'Varela Round', sans-serif;
$font_baloo: 'Baloo 2', cursive;
$font_sans_serif: sans-serif;

$color_white: #fff;
$color_white_dark0: #f6f6f6;
$color_white_dark1: #f5f2f2;
$color_white_dark2: #d4d4d4;
$color_black: #000;

$color_green_light: #00ffbb;

body {
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: $color_white;
  width: 100%;
  min-width: 360px;
  height: auto;
  font-family: $font_varela_round;

  .bg_video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .bg_image {
    position: fixed;
    width: 100%;
    height: 100%;
    display: none;
    background: url("../../assets/bg_image.jpg") no-repeat;
    background-size: cover;
    z-index: 5;
  }

  .bg_over {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(22.52% 50% at 50% 50%, rgba(33, 35, 47, 0) 15.37%, #21232f 100%);
    opacity: .55;
    z-index: 10;
  }

  .main {
    position: relative;
    width: calc(100% - 100px);
    height: calc(100% - 40px);
    top: 0;
    left: 0;
    z-index: 20;
    padding: 20px 50px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;

      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color_white;

        h1 {
          font-size: 45px;
          font-style: italic;
          margin: auto;
          font-family: $font_baloo;
        }

        small {
          margin-left: 20px;
          margin-top: 7px;
          font-size: 17px;
        }
      }

      .menu {
        display: flex;
        flex-direction: row;
        margin-top: 7px;
        justify-content: end;
        flex: 1;
        flex-wrap: wrap;
        flex-basis: 320px;
        align-items: center;

        a {
          font-size: 16px;
          text-decoration: none;
          margin: 10px 15px;
          color: $color_white;

          &:hover {
            text-decoration: underline;
          }

          &:last-child {
            background-color: $color_white;
            border-radius: 25px;
            color: $color_black;
            padding: 13px 30px;

            &:hover {
              background-color: $color_white_dark2;
              text-decoration: none;
            }
          }
        }
      }
    }

    .middle {
      display: flex;
      flex-direction: row;
      margin-top: 45px;
      flex-wrap: wrap;

      .left_box {
        flex: 1;
        display: flex;
        justify-content: center;

        img {
          height: 620px;
        }
      }

      .right_box {
        flex: 2;
        color: $color_white;
        display: flex;
        flex-direction: column;
        font-family: $font_sans_serif;
        margin-left: 25px;

        h2 {
          font-size: 33px;
        }

        h3 {
          font-size: 25px;
          font-family: $font_baloo;
          color: $color_green_light;
          text-shadow: 1px 1px 0.5px $color_black;
        }

        span {
          font-size: 18px;
          margin-top: 20px;
          line-height: 24px;
        }

        .info {
          display: flex;
          flex-direction: row;
          font-family: $font_varela_round;
          flex-wrap: wrap;

          .box {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin-top: 75px;

            span {
              font-size: 100px;

              &:last-child {
                margin-top: 50px;
                font-size: 25px;
                text-align: right;
                font-weight: normal;
              }
            }
          }

          .sep {
            width: 100px;
            height: 100px;
            margin: 80px 100px 0;
          }
        }
      }
    }
  }

  .stores {
    position: fixed;
    right: 50px;
    bottom: 30px;
    color: $color_white;
    z-index: 20;
    display: flex;
    flex-direction: row;

    .qr_box {
      background: rgba(253, 172, 178, 0.24);
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      border-radius: 16px;

      img {
        width: 90px;
        height: 90px;
      }
    }

    .store_btn {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-right: 15px;

      a {
        text-decoration: none;
      }

      img {
        width: 150px;
      }
    }
  }

  .documents {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;

    .wrapper {
      width: 75%;
      display: flex;
    }

    .doc_head {
      width: 100%;
      height: auto;
      border-bottom: 1px solid $color_white_dark0;
      justify-content: center;
      display: flex;
      padding: 15px 0;

      .wrapper {
        width: 75%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color_black;

        h1 {
          font-size: 45px;
          font-family: $font_baloo;
          margin: 0 20px 0 0;
        }

        small {
          margin-top: 15px;
          font-size: 17px;
        }
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: start;
        text-decoration: none;


        background-color: $color_white_dark1;
        border-radius: 25px;
        color: $color_black;
        padding: 13px 30px;

        &:hover {
          background-color: $color_white_dark2;
          text-decoration: none;
        }

        img {
          width: 18px;
          margin-right: 7px;
        }
      }
    }

    .doc_body {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 15px 0;

      .wrapper {
        width: 75%;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 1150px) {
  body {
    .main {
      margin-bottom: 180px;

      .header {
        .menu {
          flex-basis: 100%;
          justify-content: center;
        }
      }

      .middle {
        .right_box {
          flex-basis: 100%;

          .info {
            .box {
              span {
                font-size: 50px;
              }

            }

            .sep {
              width: 75px;
              height: 75px;
              margin: 120px 50px 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  body {
    .bg_video {
      display: none;
    }

    .bg_image {
      display: block;
    }

    .main {
      width: calc(100% - 40px);
      padding: 20px 20px;

      .middle {
        .right_box {
          flex-basis: 500px;

          .info {
            .sep {
              margin: 50px 50px 0;
            }
          }
        }
      }
    }

    .documents{
      .doc_head {
        .wrapper {
          width: 90%;
          .logo {
            flex-wrap: wrap;
          }
          a {
            padding: 13px 15px;
            img {
              margin-right: 0;
            }
            span {
              display: none;
            }
          }
        }
      }
      .doc_body {
        .wrapper {
          width: 90%;
        }
      }
    }
  }
}



