body {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  min-width: 360px;
  height: auto;
  margin: auto;
  padding: 0;
  font-family: Varela Round, sans-serif;
  display: flex;
}

body .bg_video {
  object-fit: cover;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

body .bg_image {
  z-index: 5;
  background: url("bg_image.2c55f7e2.jpg") 0 0 / cover no-repeat;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
}

body .bg_over {
  opacity: .55;
  z-index: 10;
  background: radial-gradient(22.52% 50%, #21232f00 15.37%, #21232f 100%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

body .main {
  z-index: 20;
  width: calc(100% - 100px);
  height: calc(100% - 40px);
  padding: 20px 50px;
  position: relative;
  top: 0;
  left: 0;
}

body .main .header {
  flex-flow: wrap;
  align-items: center;
  width: 100%;
  display: flex;
}

body .main .header .logo {
  color: #fff;
  flex-direction: row;
  align-items: center;
  display: flex;
}

body .main .header .logo h1 {
  margin: auto;
  font-family: "Baloo 2", cursive;
  font-size: 45px;
  font-style: italic;
}

body .main .header .logo small {
  margin-top: 7px;
  margin-left: 20px;
  font-size: 17px;
}

body .main .header .menu {
  flex-flow: wrap;
  flex: 320px;
  justify-content: end;
  align-items: center;
  margin-top: 7px;
  display: flex;
}

body .main .header .menu a {
  color: #fff;
  margin: 10px 15px;
  font-size: 16px;
  text-decoration: none;
}

body .main .header .menu a:hover {
  text-decoration: underline;
}

body .main .header .menu a:last-child {
  color: #000;
  background-color: #fff;
  border-radius: 25px;
  padding: 13px 30px;
}

body .main .header .menu a:last-child:hover {
  background-color: #d4d4d4;
  text-decoration: none;
}

body .main .middle {
  flex-flow: wrap;
  margin-top: 45px;
  display: flex;
}

body .main .middle .left_box {
  flex: 1;
  justify-content: center;
  display: flex;
}

body .main .middle .left_box img {
  height: 620px;
}

body .main .middle .right_box {
  color: #fff;
  flex-direction: column;
  flex: 2;
  margin-left: 25px;
  font-family: sans-serif;
  display: flex;
}

body .main .middle .right_box h2 {
  font-size: 33px;
}

body .main .middle .right_box h3 {
  color: #0fb;
  text-shadow: 1px 1px .5px #000;
  font-family: "Baloo 2", cursive;
  font-size: 25px;
}

body .main .middle .right_box span {
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
}

body .main .middle .right_box .info {
  flex-flow: wrap;
  font-family: Varela Round, sans-serif;
  display: flex;
}

body .main .middle .right_box .info .box {
  flex-direction: column;
  margin-top: 75px;
  font-weight: bold;
  display: flex;
}

body .main .middle .right_box .info .box span {
  font-size: 100px;
}

body .main .middle .right_box .info .box span:last-child {
  text-align: right;
  margin-top: 50px;
  font-size: 25px;
  font-weight: normal;
}

body .main .middle .right_box .info .sep {
  width: 100px;
  height: 100px;
  margin: 80px 100px 0;
}

body .stores {
  color: #fff;
  z-index: 20;
  flex-direction: row;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 50px;
}

body .stores .qr_box {
  -webkit-backdrop-filter: blur(16px);
  background: #fdacb23d;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
}

body .stores .qr_box img {
  width: 90px;
  height: 90px;
}

body .stores .store_btn {
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 15px;
  display: flex;
}

body .stores .store_btn a {
  text-decoration: none;
}

body .stores .store_btn img {
  width: 150px;
}

body .documents {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

body .documents .wrapper {
  width: 75%;
  display: flex;
}

body .documents .doc_head {
  border-bottom: 1px solid #f6f6f6;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 15px 0;
  display: flex;
}

body .documents .doc_head .wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

body .documents .doc_head .logo {
  color: #000;
  flex-direction: row;
  align-items: center;
  display: flex;
}

body .documents .doc_head .logo h1 {
  margin: 0 20px 0 0;
  font-family: "Baloo 2", cursive;
  font-size: 45px;
}

body .documents .doc_head .logo small {
  margin-top: 15px;
  font-size: 17px;
}

body .documents .doc_head a {
  color: #000;
  background-color: #f5f2f2;
  border-radius: 25px;
  flex-direction: row;
  align-items: start;
  padding: 13px 30px;
  text-decoration: none;
  display: flex;
}

body .documents .doc_head a:hover {
  background-color: #d4d4d4;
  text-decoration: none;
}

body .documents .doc_head a img {
  width: 18px;
  margin-right: 7px;
}

body .documents .doc_body {
  justify-content: center;
  width: 100%;
  padding: 15px 0;
  display: flex;
}

body .documents .doc_body .wrapper {
  flex-direction: column;
  width: 75%;
}

@media (width <= 1150px) {
  body .main {
    margin-bottom: 180px;
  }

  body .main .header .menu {
    flex-basis: 100%;
    justify-content: center;
  }

  body .main .middle .right_box {
    flex-basis: 100%;
  }

  body .main .middle .right_box .info .box span {
    font-size: 50px;
  }

  body .main .middle .right_box .info .sep {
    width: 75px;
    height: 75px;
    margin: 120px 50px 0;
  }
}

@media (width <= 800px) {
  body .bg_video {
    display: none;
  }

  body .bg_image {
    display: block;
  }

  body .main {
    width: calc(100% - 40px);
    padding: 20px;
  }

  body .main .middle .right_box {
    flex-basis: 500px;
  }

  body .main .middle .right_box .info .sep {
    margin: 50px 50px 0;
  }

  body .documents .doc_head .wrapper {
    width: 90%;
  }

  body .documents .doc_head .wrapper .logo {
    flex-wrap: wrap;
  }

  body .documents .doc_head .wrapper a {
    padding: 13px 15px;
  }

  body .documents .doc_head .wrapper a img {
    margin-right: 0;
  }

  body .documents .doc_head .wrapper a span {
    display: none;
  }

  body .documents .doc_body .wrapper {
    width: 90%;
  }
}

/*# sourceMappingURL=index.074c7404.css.map */
